<template>
  <v-container grid-list-md pa-0 relative>
    <v-layout row wrap>
      <div class="overlay">
        <v-flex xs12 sm12>
          <v-card outlined class="primary--border">
            <v-card-title
              class="title"
              v-if="this.$router.currentRoute.name == 'quick-fee-payment'"
            >
              Receipt (Quick Fee Payment)
              <v-spacer></v-spacer>
            </v-card-title>
            <v-card-title class="title" v-else>
              Receipt (Fee Payment)
              <v-spacer></v-spacer>
            </v-card-title>

            <v-card outlined>
              <v-card-title class="title pb-0">
                <v-flex xs2>
                  <v-text-field
                    placeholder="ST-76001"
                    v-model="selectedStudent.enrollCode"
                    class="pa-0"
                    label="Enroll Code"
                    outlined
                    dense
                  ></v-text-field>
                </v-flex>
                <v-flex xs5>
                  <v-student-search-field
                    :withDeleted="true"
                    @onSelectStudent="selectStudent"
                  ></v-student-search-field>
                </v-flex>
                <v-flex xs3>
                  <search-button
                    style=""
                    permission="receipt-read"
                    @action="searchStudent"
                  >
                    Search
                  </search-button>
                </v-flex>
                <v-flex xs2>
                  <v-btn
                    style="margin-top: -20px"
                    v-if="Object.keys(selectedStudent).length > 0"
                    color="primary"
                    @click="showStatement(), compKey++"
                    outlined
                  >
                    View Statement
                  </v-btn>
                </v-flex>
              </v-card-title>
            </v-card>

            <v-card-title
              v-if="
                security.amount > 0 && Object.keys(selectedStudent).length > 0
              "
            >
              <alert-message
                :title="`Security Deposit Amount  (${security.amount.currency()})`"
                type="info"
              >
                <template v-slot:message>
                  Looks like this student has refundable security deposit amount
                  remaining. Please go to Billing > Activity > Security Deposit
                  to pay all the due with student's security deposit amount at
                  once or you can pay for this student.
                  <a
                    v-if="record.length > 0"
                    href=""
                    @click.prevent="record.length ? (securityAlert = true) : ''"
                    ><u>Pay Now</u></a
                  >
                </template>
              </alert-message>
              <br />
            </v-card-title>
            <v-card-title
              v-if="
                advance.amount > 0 && Object.keys(selectedStudent).length > 0
              "
            >
              <alert-message
                :title="`Advance Amount  (${advance.amount.currency()})`"
                type="warning"
              >
                <template v-slot:message>
                  Looks like this student has advance amount remaining. Please
                  go to Billing > Activity > Advance Payment to pay all the due
                  with student's advance amount at once or you can pay for this
                  student.
                  <a
                    v-if="record.length > 0"
                    href=""
                    @click.prevent="record.length ? (advanceAlert = true) : ''"
                    ><u>Pay Now</u></a
                  >
                </template>
              </alert-message>
              <br />
            </v-card-title>
            <v-container
              v-if="
                !loading &&
                Object.keys(selectedStudent).length > 0 &&
                !record.length
              "
            >
              <br />
              <alert-message
                title="No Payment Records"
                type="success"
                :message="`No pending payments were found for ${selectedStudent.name}. Please make sure the bill was generated.`"
              />
              <br />
              <br />
            </v-container>

            <!-- :server-items-length="form.items.meta.total" -->
            <!-- :loading="form.loading" -->
            <v-data-table
              v-if="record.length"
              :headers="billingHeaders"
              v-model="selected"
              :single-expand="singleExpand"
              :options.sync="pagination"
              hide-default-footer
              :items="record"
              :expanded.sync="expanded"
              show-expand
            >
              <template v-slot:header.toggle>
                <v-checkbox
                  class="ml-0"
                  v-if="record.length"
                  :indeterminate="
                    selected.length < record.length && selected.length !== 0
                  "
                  :input-value="record.length === selected.length"
                  primary
                  @click.stop="toggleAll"
                  hide-details
                ></v-checkbox>
                <span v-else>#</span>
              </template>

              <template
                v-slot:item.data-table-expand="{ expand, isExpanded, item }"
              >
                <span v-if="selected.includes(item)">
                  <v-icon
                    style="cursor: pointer"
                    color="success"
                    v-if="isExpanded"
                    @click="expand(!isExpanded)"
                  >
                    remove_circle
                  </v-icon>
                  <v-icon
                    style="cursor: pointer"
                    color="primary"
                    v-else
                    @click="expand(!isExpanded)"
                  >
                    add_circle
                  </v-icon>
                </span>
                <span v-else>
                  <v-icon style="cursor: no-drop"> add_circle </v-icon>
                </span>
              </template>

              <template v-slot:item.toggle="{ item }">
                <v-checkbox
                  :input-value="selected.includes(item)"
                  primary
                  @click.stop="selectRecord(item)"
                  hide-details
                >
                </v-checkbox>
              </template>
              <template v-slot:item.bill_amount="{ item }">
                <strike v-if="!selected.includes(item)"
                  >{{ item.bill_amount.currency() }}
                </strike>
                <span v-else>{{ item.bill_amount.currency() }}</span>
              </template>
              <template v-slot:item.monthname="{ item }">
                {{
                  item.monthname === '13'
                    ? 'Baisakh (13)'
                    : item.monthname === '14'
                    ? 'Jestha (14)'
                    : item.monthname
                }}
              </template>
              <template v-if="include_penalty" v-slot:item.penalty="{ item }">
                <striker-component :strike="!selected.includes(item)">
                  <span
                    style="color: indianred"
                    :class="{
                      dim_color:
                        item.penalty &&
                        item.penalty.calculate_discount !== true,
                    }"
                    v-if="item.days_passed"
                    >{{ item.days_passed }} days
                    <span
                      style="color: #ff5252; font-weight: bold"
                      v-if="item.penalty"
                      >({{ item.penalty.amount.currency() }})</span
                    >
                    <span v-else>(0.00)</span>
                  </span>
                </striker-component>
              </template>

              <template v-slot:item.data-table-select="{ isSelected, select }">
                <v-simple-checkbox
                  primary
                  :value="isSelected"
                  @input="select($event)"
                ></v-simple-checkbox>
              </template>

              <template v-slot:item.discount="{ item }">
                <input
                  style="width: 110px"
                  type="number"
                  autocomplete="off"
                  class="boxField"
                  @keyup="calculation()"
                  v-model="record[item.sn].instant_discount"
                  :disabled="item.enable || bulk || true"
                />
              </template>

              <template v-slot:expanded-item="{ headers, item }">
                <td :colspan="headers.length">
                  <v-card
                    class="elevation-0"
                    style="width: 100%"
                    v-if="selected"
                  >
                    <table width="100%" class="inner-table">
                      <thead>
                        <tr>
                          <th></th>
                          <th class="text-xs-left">
                            <strong>Fee Head</strong>
                          </th>
                          <th class="text-xs-left"><strong>Amount</strong></th>
                          <th class="text-right">
                            <strong>Payment Amount</strong>
                          </th>
                          <th class="text-right"><strong>Discount</strong></th>
                        </tr>
                        <tr
                          v-for="(x, i) in item.details"
                          :key="i"
                          style="height: 0px"
                        >
                          <td></td>
                          <td class="text-xs-left">{{ x.fee_head }}</td>
                          <td class="text-xs-left">{{ x.due_amount }}</td>
                          <td class="text-right">
                            <!--  -->
                            <!-- :disabled="x.amount === x.instant_discount" -->

                            <input
                              type="number"
                              @focus="$event.target.select()"
                              autocomplete="off"
                              class="boxField"
                              @keyup="calculation(x, 'payment')"
                              v-model="x.amount"
                              :disabled="quickPayment"
                            />
                          </td>
                          <td class="text-right">
                            <input
                              style="width: 120px"
                              type="number"
                              @focus="$event.target.select()"
                              autocomplete="off"
                              class="boxField"
                              @keyup="calculation(x, 'discount')"
                              v-model="x.instant_discount"
                              :disabled="bulk || quickPayment"
                            />
                            <!-- x.amount < x.clean_amount -->
                            <!--  -->
                          </td>
                        </tr>
                      </thead>
                    </table>
                  </v-card>
                </td>
              </template>
            </v-data-table>

            <br />
            <br />
            <v-card-title v-if="record.length" class="payment_info_card_title">
              <div class="payment-info-box-container">
                <div class="table_aligner">
                  <div class="aligner">
                    <div class="title_c">
                      <span class="table_h">Total Selected Amount</span>
                    </div>
                    <div class="value_c">
                      <span class="table_v"> {{ selectedSum.currency() }}</span>
                    </div>
                  </div>
                  <div class="aligner mt-2">
                    <div class="title_c">
                      <span class="table_h"
                        ><label for="discount">Discount</label></span
                      >
                    </div>
                    <div class="value_c">
                      <span class="table_v">
                        <input
                          type="number"
                          @focus="$event.target.select()"
                          autocomplete="off"
                          class="boxField"
                          id="discount"
                          v-model="discount"
                          @keyup="discountCal"
                          :disabled="!bulk"
                        />
                      </span>
                      <v-checkbox
                        v-if="
                          this.$router.currentRoute.name !== 'quick-fee-payment'
                        "
                        label="Bulk Discount?"
                        primary
                        hide-details
                        v-model="bulk"
                      ></v-checkbox>
                    </div>
                  </div>

                  <div
                    v-if="
                      advance.amount < 1 &&
                      $auth.can('discount-and-penalty-create')
                    "
                  >
                    <hr style="margin-top: 10px" />
                    <div style="float: right">
                      <div style="display: flex">
                        <v-checkbox
                          label="Include Penalty?"
                          error
                          hide-details
                          v-model="include_penalty"
                        ></v-checkbox>
                        <span
                          v-if="penaltyAmountCalculated"
                          class="ml-2"
                          style="
                            font-size: 16px;
                            padding-top: 2px;
                            color: #ff5252 !important;
                            cursor: pointer;
                          "
                          ><u>{{ penaltyAmountCalculated }}</u>
                        </span>
                      </div>
                    </div>
                  </div>
                  <div class="clearfix"></div>
                  <div class="aligner mt-5">
                    <div class="title_c">
                      <span class="table_h"
                        ><label for="amt_to_pay">Amount to Pay</label></span
                      >
                    </div>
                    <div class="value_c">
                      <span class="table_v">
                        <input
                          @focus="$event.target.select()"
                          type="number"
                          autocomplete="off"
                          id="amt_to_pay"
                          class="boxField"
                          v-model="amount_to_pay"
                          @keyup="amtToPayKeyUp"
                          :disabled="selectedSum < totalSum"
                        />
                      </span>
                    </div>
                  </div>

                  <div
                    v-if="
                      parseFloat(amount_to_pay) + parseFloat(discount) >
                      parseFloat(totalSum)
                    "
                    class="aligner mt-3 advance_amount"
                  >
                    <div class="title_c">
                      <span class="table_h">Advance Amount</span>
                    </div>
                    <div class="value_c">
                      <span class="table_v">{{
                        (
                          parseFloat(amount_to_pay) -
                          parseFloat(totalSum) +
                          parseFloat(discount)
                        ).currency()
                      }}</span>
                    </div>
                  </div>
                  <div
                    v-if="
                      parseFloat(amount_to_pay) + parseFloat(discount) <
                      parseFloat(totalSum)
                    "
                    class="aligner mt-3 due_amount"
                  >
                    <div class="title_c">
                      <span class="table_h">Remaining Due Amount</span>
                    </div>
                    <div class="value_c">
                      <span class="table_v">
                        <!-- -->
                        <!--{{parseFloat(discount)}}-->
                        {{
                          (
                            parseFloat(totalSum) -
                            (parseFloat(amount_to_pay) + parseFloat(discount))
                          ).currency()
                        }}
                      </span>
                    </div>
                  </div>
                  <br />
                  <v-divider></v-divider>

                  <div class="aligner mt-3">
                    <div class="title_c">
                      <h4>Note</h4>
                      <p style="font-size: 12px">
                        * &nbsp;&nbsp;Please be careful any mistakes would
                        hamper the accounting process of school. <br />
                        * &nbsp;&nbsp;
                        <span
                          :class="{
                            'error--text':
                              amtToPayKeyUpInput !== 0 &&
                              amtToPayKeyUpInput + parseFloat(discount) <
                                selectedSum,
                          }"
                          >Amount to pay text field can not have lesser value
                          input in comparison to the total payable <br />amount.
                          If you want to pay less Please do it from the details
                          head.</span
                        >
                      </p>
                    </div>
                    <div class="value_c">
                      <h4>PAYING AMOUNT</h4>
                      <span style="font-size: 22px; font-weight: bold">{{
                        parseFloat(amount_to_pay).currency()
                      }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </v-card-title>
            <!---->
            <v-card-title v-if="record.length">
              <v-flex xs12 sm12 class="text-xs-right">
                <v-btn
                  large
                  style="width: 100%"
                  v-if="
                    $auth.can('receipt-create') &&
                    this.$router.currentRoute.name != 'quick-fee-payment'
                  "
                  color="primary"
                  @click="checkEveryThingIsOk"
                  ma-0
                  :disabled="
                    advance.amount > 0 ||
                    (amtToPayKeyUpInput !== 0 &&
                      amtToPayKeyUpInput + parseFloat(discount) < selectedSum)
                  "
                >
                  Pay Now
                </v-btn>
                <v-btn
                  large
                  style="width: 100%"
                  v-if="
                    $auth.can('receipt-create') &&
                    this.$router.currentRoute.name === 'quick-fee-payment'
                  "
                  color="success"
                  @click="checkEveryThingIsOk"
                  ma-0
                  :disabled="
                    advance.amount > 0 ||
                    (amtToPayKeyUpInput !== 0 &&
                      amtToPayKeyUpInput + parseFloat(discount) < 1)
                  "
                >
                  Pay Now
                </v-btn>
              </v-flex>
            </v-card-title>
          </v-card>
        </v-flex>
      </div>
    </v-layout>

    <v-dialog v-model="confirmDialog" persistent max-width="650px">
      <v-card>
        <v-card-title class="title pa-3 warning white--text">
          <v-icon class="mr-2 white--text">warning</v-icon>
          Are you sure you want to pay this amount?
        </v-card-title>
        <v-card-text class="ma-0 mt-3">
          <v-chip v-if="amount_to_pay > 0"
            >{{ parseFloat(amount_to_pay) }} ({{
              convertNumberToWords(parseFloat(amount_to_pay), true)
            }})
          </v-chip>
          <v-chip v-else> 100% Discount Given </v-chip>
          &nbsp;
        </v-card-text>
        <v-card-text>
          <v-layout row wrap pa-3>
            <v-flex xs3 sm3 class="bankInfo">
              <v-select
                :items="paymentMethod"
                label="Payment Method"
                v-model="payment_method"
                outlined
                dense
              />
            </v-flex>
            <v-flex xs4 sm4 v-if="payment_method === 'cheque'" class="bankInfo">
              <v-text-field
                v-model="cheque_no"
                label="Cheque No"
                outlined
                dense
              />
            </v-flex>
            <v-flex xs4 sm4 v-if="payment_method === 'cheque'" class="bankInfo">
              <v-text-field
                v-model="bank_name"
                label="Bank Name"
                outlined
                dense
              />
            </v-flex>
            <v-flex xs12 sm12>
              <v-textarea
                rows="2"
                outlined
                dense
                v-model="remarks"
                label="Remarks"
                hint="Comment any other information"
              />
            </v-flex>
          </v-layout>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="success"
            outlined
            small
            @click="
              (confirmDialog = false),
                (remarks = ''),
                (payment_method = 'cash'),
                (cheque_no = ''),
                (bank_name = '')
            "
            >Cancel
          </v-btn>
          <v-btn
            color="warning"
            outlined
            small
            @click="submit"
            :disabled="!payment_method || remarks.length < 4"
            >Confirm</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="advanceAlert" persistent max-width="650px">
      <v-card>
        <v-card-title class="title pa-3 success white--text">
          <v-icon class="mr-2 white--text">warning</v-icon>
          Student have remaining advance ({{ advance.amount.currency() }})
        </v-card-title>
        <br />
        <v-card-text>
          <br />
          <alert-message type="success" title="Advance Payment">
            <template v-slot:message>
              Total Advance Amount being paid is
              <span style="color: #000"
                >&nbsp;&nbsp;{{ advance.amount.currency() }}</span
              >
              for bill due amount amount
              <span style="color: #000">{{
                totalConstDueAmount.currency()
              }}</span>
            </template>
          </alert-message>
          <br />
          <br />
          <hr />
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="warning" outlined small @click="advanceAlert = false"
            >Cancel
          </v-btn>
          <v-btn
            color="success"
            outlined
            small
            @click="payWithAdvance"
            :disabled="!payment_method"
            >Pay Now</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="securityAlert" persistent max-width="650px">
      <v-card>
        <v-card-title class="title pa-3 info white--text">
          <v-icon class="mr-2 white--text">warning</v-icon>
          Student have refundable security deposit amount ({{
            security.amount.currency()
          }})
        </v-card-title>
        <br />
        <v-card-text>
          <br />
          <alert-message type="info" title="Security Deposit Payment">
            <template v-slot:message>
              Total Security Deposit Amount being paid is
              <span style="color: #000"
                >&nbsp;&nbsp;{{ security.amount.currency() }}</span
              >
              for bill due amount amount
              <span style="color: #000">{{
                totalConstDueAmount.currency()
              }}</span>
            </template>
          </alert-message>
          <br />
          <br />
          <hr />
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="warning" outlined small @click="securityAlert = false"
            >Cancel
          </v-btn>
          <v-btn
            color="success"
            outlined
            small
            @click="payWithSecurityDeposit"
            :disabled="!payment_method"
            >Pay Now</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="previousDueAlert" persistent max-width="700px">
      <v-card>
        <v-card-title class="title pa-3 warning white--text">
          <v-icon class="mr-2 white--text">warning</v-icon>
          Pending Previous Dues ({{ pendingPreviousDues.amount.currency() }})
        </v-card-title>
        <br />
        <v-card-text>
          <alert-message
            :extraSmall="true"
            type="warning"
            title="Pending Previous Dues"
          >
            <template v-slot:message>
              <span style="color: #000"
                >&nbsp;&nbsp;{{ selectedStudent.name }}</span
              >
              have pending previous dues.
              <span style="color: #000">{{
                pendingPreviousDues.amount.currency()
              }}</span
              >. However bill has not yet been generated for his/her class. We
              recommend you to generate his/her bill first.
            </template>
          </alert-message>
          <br />
          <v-card-title>
            <v-flex xs4>
              <v-text-field
                v-model="pendingPreviousDues.payable_amount"
                autocomplete="off"
                label="Previous Due (Input Paying Amount)"
                required
                class="pa-0"
                outlined
                dense
                name="name"
              />
            </v-flex>
            <v-flex xs4>
              <v-text-field
                autocomplete="off"
                label="Discount"
                required
                class="pl-2"
                v-model="pendingPreviousDues.discount"
                outlined
                dense
                name="name"
              />
            </v-flex>
            <v-flex xs4>
              <v-text-field
                autocomplete="off"
                disabled
                label="Total Paying Amount"
                required
                class="pl-2"
                :value="
                  (
                    parseFloat(pendingPreviousDues.discount) +
                    parseFloat(pendingPreviousDues.payable_amount)
                  ).currency()
                "
                outlined
                dense
                name="name"
              />
            </v-flex>
            <v-flex xs12>
              <v-textarea
                type="number"
                rows="2"
                outlined
                dense
                v-model="pendingPreviousDues.remarks"
                label="Remarks"
                hint="Comment any other information"
              />
            </v-flex>

            <v-flex xs12>
              <v-chip
                v-if="!previousDuePaymentInvalid"
                :color="previousDueStatus >= 1 ? 'warning' : 'success'"
                label
                block
                style="width: 100%"
              >
                Paying Amount
                {{
                  (
                    parseFloat(pendingPreviousDues.discount) +
                    parseFloat(pendingPreviousDues.payable_amount)
                  ).currency()
                }}.
                {{
                  previousDueStatus >= 1
                    ? `You still will have ${previousDueStatus.currency()} remaining previous dues`
                    : `All Previous Dues cleared (No Pending Dues)`
                }}
              </v-chip>
              <v-chip v-else label style="width: 100%" color="error">
                Invalid (Please recheck your amounts)
              </v-chip>
            </v-flex>
          </v-card-title>

          <hr />
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="warning"
            outlined
            small
            @click="previousDueAlert = false"
            >Cancel
          </v-btn>
          <v-btn
            :disabled="
              previousDuePaymentInvalid ||
              pendingPreviousDues.remarks.length < 4
            "
            color="success"
            outlined
            :loading="pendingPreviousDues.loading"
            small
            @click="payPreviousDues"
            >Pay Previous Dues Now
          </v-btn>
          <v-btn
            color="primary"
            outlined
            small
            @click="$router.push({ name: 'bill-generate' })"
            :disabled="!payment_method"
            >Generate Bill Instead</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="showStatementModal" max-width="1100px">
      <v-card>
        <v-card-title class="title pa-3 info white--text">
          <v-icon class="mr-2 white--text">info</v-icon>
          Statement Record Of {{ selectedStudent.name }} ({{
            selectedStudent.enrollCode
          }})
        </v-card-title>
        <v-card-text>
          <br />
          <statement
            :key="compKey"
            :enrollCodeProp="selectedStudent.enrollCode"
            :reuseComponent="false"
          ></statement>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="warning"
            outlined
            small
            @click="showStatementModal = false"
            >Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-overlay :value="searchingDue">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </v-container>
</template>
<script>
import { mapState } from 'vuex';

import { convertNumberToWords } from '@/library/helpers';

import { isCurrentBatch } from '../../../../library/helpers';
import AlertMessage from '../../../../components/AlertMessage.vue';
import StrikerComponent from '../../../../components/StrikerComponent.vue';
import Mixins from '@/library/Mixins';
import Statement from '../Statement/index.vue';
// import Statement from '../Statement/indexOld.vue';
export default {
  components: { AlertMessage, StrikerComponent, Statement },
  data: () => ({
    compKey: 0,
    mixins: [Mixins],
    studentStatement: [],
    showStatementModal: false,
    previousDueAlert: false,
    pendingPreviousDues: {
      amount: 0,
      payable_amount: 0,
      remarks: '',
      data: [],
      discount: 0,
      loading: false,
    },
    selectedStudent: {},
    totalConstDueAmount: 0,
    searchingDue: false,
    loading: true,
    selected: [],
    pagination: {
      itemsPerPage: 100,
      sortBy: ['roll'],
    },
    advance: {
      amount: 0,
    },
    advanceAlert: false,
    security: {
      amount: 0,
    },
    securityAlert: false,
    expanded: [],
    singleExpand: false,
    discount: 0,
    expand: false,
    bulk: true,
    record: [],
    amount_to_pay: 0,
    selectedSum: 0,
    search: null,
    headers: [
      { text: '#', align: 'left', value: 'toggle', sortable: false, width: 10 },
      {
        text: 'Invoice No',
        align: 'left',
        value: 'invoice_no',
        sortable: false,
      },
      {
        text: 'Bill Months',
        align: 'left',
        value: 'monthname',
        sortable: false,
      },
      {
        text: 'Due Amount',
        align: 'left',
        value: 'bill_amount',
        sortable: false,
      },
      {
        text: 'Penalty',
        align: 'left',
        value: 'penalty',
        sortable: false,
      },
      {
        text: 'Discount',
        align: 'right',
        value: 'discount',
        sortable: false,
        width: 80,
      },
    ],
    paymentMethod: [
      { text: 'Cash', value: 'cash' },
      { text: 'Cheque', value: 'cheque' },
      { text: 'Esewa Qr', value: 'esewa-qr' },
    ],
    studentData: false,
    payment_method: 'cash',
    confirmDialog: false,
    printed_by: '',
    remarks: '',
    bank_name: '',
    cheque_no: '',
    totalSum: 0,
    amtToPayKeyUpInput: 0,
    include_penalty: false,
    total_penalty: 0,
    penalty_collections: [],
    quickPayment: false,
  }),

  computed: {
    ...mapState(['batch']),
    penaltyAmountCalculated() {
      return false;
    },
    billingHeaders() {
      let headers = [];
      this.headers.map((item) => {
        if (item.text === 'Penalty' && this.include_penalty !== true) {
          return;
        }
        headers.push(item);
      });
      return headers;
    },

    previousDueStatus() {
      const amount =
        parseFloat(this.pendingPreviousDues.discount) +
        parseFloat(this.pendingPreviousDues.payable_amount);
      return this.pendingPreviousDues.amount - amount;
    },
    previousDuePaymentInvalid() {
      return (
        (!parseFloat(this.pendingPreviousDues.payable_amount) &&
            !parseFloat(this.pendingPreviousDues.discount)) || 
            ((parseFloat(this.pendingPreviousDues.payable_amount) + parseFloat(this.pendingPreviousDues.discount)) > parseFloat(this.pendingPreviousDues.amount))
      );
    },
  },

  mounted() {
    // this.currentSod();

    const { enroll_code, _s_, _e_i_, name } = this.$route.query;

    if (enroll_code && _s_ && _e_i_) {
      this.selectedStudent = {
        name: name,
        enrollCode: enroll_code,
        sectionId: _s_,
        enrollId: _e_i_,
      };
      this.searchStudent();
    }
    this.searchStudent();
    this.disableInput();
  },

  watch: {
    include_penalty: function (value) {
      if (value === true && this.penalty_collections.length < 1) {
        this.$rest
          .post('/api/discount-penalty/calculate', {
            enroll_id: this.selectedStudent.enrollId,
            enroll_code: this.selectedStudent.enrollCode,
            invoices: { ...this.record },
          })
          .then((res) => {
            this.penalty_collections = res.data;
            this.calculation();
          })
          .catch(({ response }) => {
            this.include_penalty = false;
            this.$events.fire('notification', {
              message: response.data.message,
              status: 'error',
            });
          });
      } else {
        this.calculation();
      }
    },
    bulk: function (value) {
      if (value === true) {
        this.record.map((item) => {
          item.details.map((bill) => {
            bill.instant_discount = 0;
          });
          item.instant_discount = 0;
        });
      } else {
        this.discount = 0;
        this.calculation();
      }
    },
    selectedStudent: function (value) {
      let ss = { ...value };
      this.studentData = false;
      if (Object.keys(ss).length > 0) {
        if (value && value.length > 7) {
          this.searchStudent();
        }
      } else {
        this.record = [];
        this.search = '';
        this.remarks = '';
      }
    },
    'pendingPreviousDues.discount': function (value) {
      // this.pendingPreviousDues.payable_amount =
      //   this.pendingPreviousDues.amount - value;
    },
  },
  methods: {
    isCurrentBatch,
    convertNumberToWords,
    checkEveryThingIsOk() {
      let isValid = true;
      this.record.map((item) => {
        item.details.map((detail) => {
          if (parseInt(detail.amount) < parseInt(detail.instant_discount)) {
            isValid = false;
          } else if (
            parseInt(detail.amount) === parseInt(detail.instant_discount) &&
            parseInt(detail.amount) !== parseInt(detail.clean_amount) &&
            parseInt(detail.amount) !== 0
          ) {
            // isValid = false;
          } else if (
            parseInt(detail.clean_amount) > parseInt(detail.amount) &&
            parseInt(detail.instant_discount) > 0
          ) {
            // isValid = false;
          }
        });
      });

      if (isValid) {
        this.confirmDialog = true;
      } else {
        this.$events.fire('notification', {
          message: 'Amount are placed incorrectly please check and try again',
          status: 'error',
        });
      }
    },
    selectRecord(item) {
      if (this.selected.indexOf(item) > -1)
        this.selected.splice(this.selected.indexOf(item), 1);
      else this.selected.push(item);
      this.calculation();
    },
    selectStudent(student) {
      if (!student) {
        this.selectedStudent = {};
        this.record = [];
        this.search = '';
        this.remarks = '';
      } else {
        this.selectedStudent = {
          enrollCode: student.enroll_code,
          sectionId: student.details.section_id,
          enrollId: student.details.enroll_id,
          name: student.name,
        };
        //remove the query string on student search.
        window.history.replaceState(null, '', location.href.split('?')[0]);
        this.searchStudent();
      }
    },
    amtToPayKeyUp(e) {
      this.amtToPayKeyUpInput = parseFloat(e.target.value);
    },
    searchStudent() {
      if (!this.selectedStudent.enrollCode) return false;
      this.loading = true;
      this.advance = { amount: 0 };
      this.penalty_collections = [];
      this.include_penalty = false;
      this.searchingDue = true;
      let url =
        '/api/invoice/student?rowsPerPage=100&enrollId=' +
        this.selectedStudent.enrollId +
        '&sectionId=' +
        this.selectedStudent.sectionId +
        '&enrollCode=' +
        this.selectedStudent.enrollCode;

      this.$rest
        .get(url)
        .then(({ data }) => {
          if (data.previous_dues.length > 0) {
            this.previousDueAlert = true;
            this.pendingPreviousDues.data = data.previous_dues;
            this.pendingPreviousDues.amount = 0;
            this.pendingPreviousDues.remarks = '';
            data.previous_dues.map((item) => {
              this.pendingPreviousDues.amount += item.amount;
            });
            this.pendingPreviousDues.payable_amount =
              this.pendingPreviousDues.amount;
          }

          this.selected = [];
          this.totalSum = 0;
          this.amount_to_pay = 0;
          this.record = data.data.map((item) => {
            item.clean_amount = item.amount;
            return item;
          });

          //show the advance alert dialog if there is advance
          this.advance = data.advance;
          if (this.advance && this.advance.amount > 0 && data.data.length > 0) {
            this.advanceAlert = true;
          } else {
            this.advanceAlert = false;
          }

          //show the security deposit alert dialog if there is security deposit
          this.security = data.security;
          if (
            this.security &&
            this.security.amount > 0 &&
            data.data.length > 0
          ) {
            this.securityAlert = false;
          } else {
            this.securityAlert = false;
          }

          this.selectedSum = 0;
          this.discount = 0;
          this.record.map((res, index) => {
            res.bill_amount = 0;
            res.instant_discount = 0;
            res.details.map((detail) => {
              detail.amount = detail.due_amount;
              res.bill_amount += detail.due_amount;
            });
            res.enable = false;
            res.sn = index;
            this.totalSum += res.bill_amount;
            this.amount_to_pay += res.bill_amount;
            this.selectedSum += res.bill_amount;
            this.selected.push(res);
          });
          this.totalConstDueAmount = this.totalSum;
          // }
        })
        .catch((e) => {
          this.$events.fire('notification', {
            message: 'No record found.',
            status: 'error',
          });
        })
        .finally(() => {
          this.loading = false;
          this.searchingDue = false;
        });
    },
    payPreviousDues() {
      let FormData = {
        enroll_id: this.selectedStudent.enrollId,
        enroll_code: this.selectedStudent.enrollCode,
        amount: this.pendingPreviousDues.payable_amount,
        discount: this.pendingPreviousDues.discount,
        remarks: this.pendingPreviousDues.remarks,
      };
      this.pendingPreviousDues.loading = true;

      this.$rest
        .post('/api/fee-payment-previous-dues', FormData)
        .then(({ data }) => {
          this.$events.fire('notification', {
            message: 'Previous dues were paid successfully',
            status: 'success',
          });
          this.pendingPreviousDues = {
            amount: 0,
            payable_amount: 0,
            data: [],
            discount: 0,
            remarks: '',
            loading: false,
          };
          this.previousDueAlert = false;
          window.open(data.data.url);
        })
        .catch((e) => {
          this.$events.fire('notification', {
            message: e.response.data.message,
            status: 'error',
          });
        })
        .finally((err) => {
          this.pendingPreviousDues.loading = false;
        });
    },
    save() {
      if (
        this.payment_method === 'cheque' &&
        (!this.cheque_no || !this.bank_name)
      ) {
        this.$events.fire('notification', {
          message: 'Please fill the  Bank/Cheuqe information correctly.',
          status: 'error',
        });
      } else {
        this.confirmDialog = true;
      }
    },
    submit() {
      if (
        this.payment_method === 'cheque' &&
        (!this.cheque_no || !this.bank_name)
      ) {
        this.$events.fire('notification', {
          message: 'Please fill the  Bank/Cheuqe information correctly.',
          status: 'error',
        });
      } else {
        let url, FormData;
        if (this.$router.currentRoute.name === 'quick-fee-payment') {
          url = '/api/instant-payment';
          FormData = {
            bulk: this.bulk ? 'true' : 'false',
            discount_amount: this.discount,
            via: this.payment_method,
            enroll_code: this.selectedStudent.enrollCode,
            amount: this.amount_to_pay,
            cheque_no: this.cheque_no,
            bank_name: this.bank_name,
            include_penalty: this.include_penalty ? 'true' : 'false',
            cheque_no: this.cheque_no,
            bank_name: this.bank_name,
            // sod_date: this.sod_date,
            remarks: this.remarks,
            printed_by: this.$auth.user().full_name,
          };
        } else {
          url = '/api/fee-payment-v2';
          FormData = {
            bulk: this.bulk ? 'true' : 'false',
            discount_amount: this.discount,
            enroll_id: this.selectedStudent.enrollId,
            enroll_code: this.selectedStudent.enrollCode,
            total_amount: this.amount_to_pay,
            invoices: { ...this.selected },
            include_penalty: this.include_penalty ? 'true' : 'false',
            via: this.payment_method,
            advance:
              parseFloat(this.amount_to_pay) + parseFloat(this.discount) >
              this.totalSum
                ? parseFloat(this.amount_to_pay) +
                  parseFloat(this.discount) -
                  this.totalSum
                : 0,
            cheque_no: this.cheque_no,
            bank_name: this.bank_name,
            // sod_date: this.sod_date,
            remarks: this.remarks,
            printed_by: this.$auth.user().full_name,
          };
        }
        this.searchingDue = true;
        this.$rest
          .post(`${url}`, FormData)
          .then(({ data }) => {
            this.$events.fire('notification', {
              message: data.message,
              status: 'success',
            });
            this.selectedStudent = {};
            this.record = [];
            this.remarks = '';
            this.payment_method = 'cash';
            this.search = '';
            this.confirmDialog = false;
            this.expand = false;
            this.bulk = true;
            window.open(data.data.url);
          })
          .catch((e) => {
            if (e.response)
              this.$events.fire('notification', {
                message: e.response.data.message,
                status: 'error',
              });
            this.confirmDialog = false;
          })
          .finally((err) => {
            this.searchingDue = false;
          });
      }
    },

    toggleAll() {
      if (this.selected.length) this.selected = [];
      else this.selected = this.record.slice();
      this.calculation();
    },

    calculation() {
      this.amount_to_pay = 0;
      this.discount = 0;
      this.selectedSum = 0;
      let totalPenalty = 0;

      this.selected.map((res) => {
        /**
         * Penalty Calculation Begins Here *****
         */
        if (this.include_penalty === true) {
          this.penalty_collections.map((penaltyItem) => {
            if (res.id === penaltyItem.id) {
              res.days_passed = penaltyItem.days_passed;
              res.penalty = penaltyItem.penalty;
              if (
                penaltyItem.penalty &&
                penaltyItem.penalty.calculate_discount === true
              )
                totalPenalty += penaltyItem.penalty.amount;
            }
          });
        } else {
          delete res.days_passed;
          delete res.penalty;
        }

        /**
         * Penalty Calculation Ends Here *******
         */

        let sumInstantDiscount = 0;
        res.details.map((detail) => {
          if (!detail.amount) detail.amount = 0;
          this.amount_to_pay += parseFloat(detail.amount);
          detail.amount =
            detail.amount >= detail.due_amount
              ? detail.due_amount
              : detail.amount;
          detail.instant_discount = !this.bulk
            ? parseFloat(detail.instant_discount) >
              parseFloat(detail.due_amount)
              ? parseFloat(detail.due_amount)
              : parseFloat(detail.instant_discount)
            : 0;
          sumInstantDiscount += parseFloat(detail.instant_discount);
          this.selectedSum += parseFloat(detail.amount);
        });
        res.instant_discount = !this.bulk
          ? !['', NaN, undefined].includes(sumInstantDiscount)
            ? sumInstantDiscount
            : 0
          : 0;

        this.discount += res.instant_discount;
      });

      this.amount_to_pay =
        parseFloat(this.selectedSum) - parseFloat(this.discount);

      if (['', NaN, undefined].includes(this.amount_to_pay)) {
        this.amount_to_pay = 0;
      }

      this.selectedSum = this.selectedSum + totalPenalty;
      this.amount_to_pay = this.amount_to_pay + totalPenalty;

      this.totalSum = this.totalSum - this.total_penalty;
      this.totalSum = this.totalSum + totalPenalty;
      this.total_penalty = totalPenalty;
    },

    /**
     * This function is called on bulk discount's value input
     */
    discountCal() {
      if (this.discount > this.selectedSum) this.discount = this.selectedSum;
      this.amount_to_pay =
        parseFloat(this.selectedSum) - parseFloat(this.discount);
      if (['', NaN, undefined].includes(this.amount_to_pay)) {
        this.amount_to_pay = 0;
      }
    },

    /**
     * When the student has advance the modal dialog appears with a pay button
     */
    payWithAdvance() {
      this.searchingDue = true;
      this.$rest
        .post('/api/advance/single-pay', {
          enroll_code: this.selectedStudent.enrollCode,
          enroll_id: this.selectedStudent.enrollId,
        })
        .then((res) => {
          this.advanceAlert = false;

          this.searchStudent();
        })
        .catch((err) => {
          if (err.response)
            this.$events.fire('notification', {
              message: err.response.data.message,
              status: 'error',
            });
          this.confirmDialog = false;
        })
        .finally((res) => {
          this.searchingDue = false;
        });
    },
    payWithSecurityDeposit() {
      this.searchingDue = true;
      this.$rest
        .post('api/security-deposit/pay', {
          enroll_code: this.selectedStudent.enrollCode,
        })
        .then((res) => {
          this.securityAlert = false;
          this.searchStudent();
          this.$events.fire('notification', {
            message: res.data.message,
            status: 'success',
          });
        })
        .catch((err) => {
          this.$events.fire('notification', {
            message: err.data.message,
            status: 'error',
          });
        })
        .finally(() => {
          this.searchingDue = false;
        });
    },
    disableInput() {
      if (this.$router.currentRoute.name === 'quick-fee-payment')
        this.quickPayment = true;
    },
    showStatement() {
      this.showStatementModal = true;

      // let extraParams = "&enrollCode=" + this.selectedStudent.enrollCode;
      // this.$rest
      //   .get(`/api/report/statement?rowsPerPage=100&${extraParams}`)
      //   .then(({ data }) => {
      //     this.showStatementModal = true;
      //     this.studentStatement = data.data.map((res) => {
      //       return res;
      //     });
      //   });
    },
  },
};
</script>
<style lang="scss" scoped>
.payment_info_card_title {
  background-color: #f8f9fb;
}

.payment-info-box-container {
  .advance_amount {
    color: #22a67a !important;
  }
  .due_amount {
    color: #eb5a46 !important;
  }
  width: 100%;
  h4 {
    color: #aaaeb1;
    font-size: 16px;
    text-transform: uppercase;
    margin-bottom: 5px;
  }
  h3,
  h1 {
    color: #28323e;
    font-weight: 500;
  }
  h1 {
    font-size: 38px;
  }
  p {
    color: #8c9195;
    font-size: 16px;
    font-weight: normal;
    margin-top: 10px;
  }
  .table_aligner {
    padding: 0 30px;
  }
  .aligner {
    font-size: 17px;
    display: flex;
    justify-content: space-between;
    .value_c {
      text-align: right;
    }
    p {
      max-width: 550px;
      line-height: 1.4;
    }
  }
  .payment-info-box-card {
    border-radius: 15px;
    padding: 15px;
    background-color: white;
    display: flex;
    justify-content: space-between;
    box-shadow: 1px 1px 5px 1px rgba(0, 0, 0, 0.04);
    p {
      line-height: 1.5;
      max-width: 500px;
    }
    .invoice_for {
      padding-left: 20px;
    }
    .amount_due_box {
      border-radius: 10px;
      background-color: #f5f8fd;
      padding: 15px 60px 15px 35px;
      h4:last-child {
        color: #9ea2ae;
        font-size: 16px;
        margin-top: 13px;
        margin-bottom: 0;
      }
    }
  }
}

table.v-datatable.v-table.theme--light {
  width: 100%;
}

.v-input--selection-controls {
  margin-top: 0;
}

.amountPayable {
  position: absolute;
  margin-top: 23px;
  font-size: 10px;
  font-weight: 600;
  margin-left: -80px;
}

.boxField {
  border: 1px solid #ccc;
  font-weight: bold;
  padding-right: 10px;
  border-radius: 3px;
  width: 140px;
  background-color: white;
  height: 35px !important;
  /*direction: rtl;*/
  text-indent: 10px;

  text-align: right !important;
  /*padding-right: 8px;*/
  &:focus {
    outline: none;
  }
}
.dim_color {
  opacity: 0.4;
}

.boxField:disabled {
  background: #bfbaba3d;
  cursor: no-drop;
}

.bankInfo {
  margin-left: 5px;
  width: 100%;
}

.highlight {
  td {
    font-weight: bold !important;
  }
}

tr.highlight {
  cursor: pointer;
}

// Css For Statement Begin

.brief_info {
  display: flex;
  width: 100%;
  .std_img {
    overflow: hidden;
    border-radius: 10px;
    width: 70px;
    img {
      width: 100%;
    }
  }
}
.std_info {
  padding-top: 0;
  padding-left: 25px;
  h3 {
    font-size: 18px;
    color: #444;
    + p {
      font-size: 14px;
      color: #c3c3c3;
      margin-bottom: 10px;
    }
  }
  .info_std_item {
    i {
      font-size: 16px;
      color: #999;
      margin-right: 5px;
    }
    color: #666;
    font-weight: bold;
    margin-right: 40px;
  }
}
// Table Css
.styled-table {
  border-collapse: collapse;
  margin: 25px 0;
  font-size: 0.9em;
  font-family: sans-serif;
  min-width: 400px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
  width: 100%;
}
.styled-table thead tr {
  background-color: #009879;
  color: #ffffff;
  text-align: left;
}
.styled-table th,
.styled-table td {
  padding: 12px 15px;
}
.styled-table tbody tr {
  border-bottom: 1px solid #dddddd;
}

.styled-table tbody tr:nth-of-type(even) {
  background-color: #f3f3f3;
}

.styled-table tbody tr:last-of-type {
  border-bottom: 2px solid #009879;
}
</style>
